<template>
  <div class="error-page">
    <div class="error-page__wrapper">
      <div class="error-page__message">
        <h1 class="error-page__message-title">
          {{ errorText.title }}
        </h1>
        <div class="error-page__message-subtitle">
          {{ errorText.subtitle }}
        </div>
        <p class="error-page__message-text">
          {{ errorText.text }}
        </p>
        <p class="error-page__message-text">
          {{ errorText.subtext }}
        </p>
      </div>
      <v-button class="error-page__button" primary @click="goFallback">
        {{ errorText.button }}
      </v-button>
      <div :class="['error-page__img', type === '500' && 'error-page__img--error-500']">
        <img src="/images/illustration.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import { ENTRY_ROUTE_NAME_FOR_REDIRECT } from '@/constants/routes'
import { ERROR_CODE_NETWORK_ERROR } from '@/constants/http'
import { loadingService } from '@/services/loading'

export default {
  name: 'Error',
  components: { VButton },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    errorText() {
      if (this.type === ERROR_CODE_NETWORK_ERROR.toString()) {
        return {
          title: '500',
          subtitle: 'Ого. Что-то сломалось.',
          text: 'Мы уже получили уведомление и работаем над исправлением.',
          subtext: 'Попробуйте вернуться через 15 минут.',
          button: 'Вернуться на главную'
        }
      }
      return {
        title: '404',
        subtitle: 'Ого. Что-то сломалось.',
        text: 'Страница, которую вы ищете, устарела или не существует.',
        button: 'Вернуться на главную'
      }
    }
  },
  created() {
    loadingService.setGlobalLoading(false)
    loadingService.setViewLoading(false)
  },
  methods: {
    goFallback() {
      this.$router.push({ name: ENTRY_ROUTE_NAME_FOR_REDIRECT })
    }
  }
}
</script>
